import React, { FC } from 'react'

import { Config } from '@bugsnag/core/types'
import BugsnagObject from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { onError } from './bugsnagHelpers'

const config: Config = {
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || 'keyplaceholder',
  enabledReleaseStages: ['production', 'staging', 'sandbox'],
  releaseStage: process.env.REACT_APP_BUGSNAG_STAGE,
  plugins: [new BugsnagPluginReact()],
  onError,
}

BugsnagObject.start(config)

const ErrorBoundary = BugsnagObject.getPlugin('react')!.createErrorBoundary(React)

const Bugsnag: FC = ({ children }) => <ErrorBoundary>{children}</ErrorBoundary>

export default Bugsnag
