type ObjectType = {
  [key: string]: string
}

const object: ObjectType = {
  realestatepi: 'honanrealestateau',
  piproposal: 'agilepiau',
}

export default object
