import { Suspense, lazy } from 'react'

import Bootstrapping from 'components/Bootstrapping'
import mappedProposalToSlug from 'mappedProposalToSlug'

const subdomain: string = window.location.host.split('.')[0]
const MaintenanceComponent = lazy(() => import(`website/components/Maintenance`))
const ProductComponent = lazy(() => import(`website/components/Website`))
const ProposalComponent = lazy(() => import(`website/components/Website/Proposal`))
const ReviewAppComponent = lazy(() => import(`apps/review`))
const PartnersAppComponent = lazy(() => import(`apps/partners`))
const MyAppComponent = lazy(() => import(`apps/my`))
const TeamAppComponent = lazy(() => import(`apps/team`))

const MaintenanceApp = () => (
  <Suspense fallback={<Bootstrapping />}>
    <MaintenanceComponent />
  </Suspense>
)

const ReviewApp = () => (
  <Suspense fallback={<Bootstrapping />}>
    <ReviewAppComponent />
  </Suspense>
)

const PartnersApp = () => (
  <Suspense fallback={<Bootstrapping />}>
    <PartnersAppComponent />
  </Suspense>
)

const TeamApp = () => (
  <Suspense fallback={<Bootstrapping />}>
    <TeamAppComponent />
  </Suspense>
)

const MyApp = () => (
  <Suspense fallback={<Bootstrapping />}>
    <MyAppComponent />
  </Suspense>
)

const ProposalSite = ({ slug }: { slug: string }) => (
  <Suspense fallback={<Bootstrapping />}>
    <ProposalComponent slug={slug} />
  </Suspense>
)

/**
 * A Product page needs to build theme and product info based on the product
 * slug derived from the subdomain
 */
const ProductSite = () => (
  <Suspense fallback={<Bootstrapping />}>
    <ProductComponent />
  </Suspense>
)

const BundleSelector = () => {
  // @ts-ignore
  if (window.MAINTENANCE_MODE) return <MaintenanceApp />

  if (subdomain === 'review') return <ReviewApp />

  if (subdomain === 'partners') return <PartnersApp />

  if (subdomain === 'team') return <TeamApp />

  if (subdomain === 'my') return <MyApp />

  if (mappedProposalToSlug[subdomain]) return <ProposalSite slug={mappedProposalToSlug[subdomain]} />

  return <ProductSite />
}

export default BundleSelector
